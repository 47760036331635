import get from 'lodash/get';
var LOCALHOST_URLS = ['http://localhost', 'https://localhost'];
export var HOST_BLOCK_LIST = ['file:///', 'chrome-extension://', 'https://analytics.tiktok.com'].concat(LOCALHOST_URLS);
var URL_BLOCK_LIST = ['file:///'].concat(LOCALHOST_URLS);
var FILENAME_BLOCK_LIST = ['SyntaxError: Unexpected token', '(unknown)', 'https://s.pinimg.com', 'https://www.youtube.com/s/player/'];
var MESSAGE_BLOCK_LIST_ANY_ENV = [/^GraphQL.+Operation (\w)+ failed with You are not (authorized|allowed) to perform this action/];
var MESSAGE_BLOCK_LIST_PRODUCTION = [].concat(MESSAGE_BLOCK_LIST_ANY_ENV, [/^Minified React error #418[^0-9].*/, // https://reactjs.org/docs/error-decoder.html?invariant=418
/^Minified React error #423[^0-9].*/, // https://reactjs.org/docs/error-decoder.html?invariant=423
/^Minified React error #425[^0-9].*/, // https://reactjs.org/docs/error-decoder.html?invariant=425
// This is quite rare and is hard to debug, might happen when the user has connection issues
// or just slow connection, reloads the page or closes the tab before the request is finished.
/^The user aborted a request\./, /^Error: Invariant: attempted to hard navigate to the same URL.*/, /^TypeError: Failed to fetch/]);
var MESSAGE_BLOCK_LIST_STAGING = [].concat(MESSAGE_BLOCK_LIST_ANY_ENV);
export var getIgnoredMessages = function getIgnoredMessages(environment) {
  return environment === 'production' ? MESSAGE_BLOCK_LIST_PRODUCTION : MESSAGE_BLOCK_LIST_STAGING;
};
export var isIgnoredByHost = function isIgnoredByHost(_isUncaught, _args, item) {
  try {
    var url = get(item, 'request.url', '');
    if (!url) return false;
    return URL_BLOCK_LIST.some(function (blockedHost) {
      return url.startsWith(blockedHost);
    });
  } catch (_unused) {
    return false;
  }
};
export var isIgnoredByFilename = function isIgnoredByFilename(_isUncaught, _args, item) {
  try {
    var frames = get(item, 'body.trace.frames', []);
    return frames.some(function (_ref) {
      var filename = _ref.filename;
      return filename && FILENAME_BLOCK_LIST.some(function (blockedFile) {
        return filename.startsWith(blockedFile);
      });
    });
  } catch (_unused2) {
    return false;
  }
};